import { createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";
import { API_ENDPOINTS } from "src/api";
import stbServer from "../../utils/axios";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  isPersonalInfoLoading: true,
  isBusinessDocsLoading: true,
  isPersonalDocsLoading: true,
  isShareholdersListLoading: true,
  isBusinessInfoLoading: true,
  isTimezoneLoading: true,
  isBusCategoryLoading: true,
  isBusTypesLoading: true,
  isOccupationTypesLoading: true,
  isMccCodesLoading: true,
  isBusinessDocTypesLoading: true,
  error: null,
  kycLevels: [],
  personalKycDocTypes: [],
  timezones: [],
  personalInfo: null,
  personalDocuments: null,
  businessInfo: null,
  businessCategories: [],
  businessTypes: [],
  shareholdersList: [],
  businessDocuments: null,
  occupationTypes: [],
  businessDocTypes: [],
  shareholderDocTypes: [],
  mccCodes: [],
};

const slice = createSlice({
  name: "kyc",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    startPersonalInfoLoading(state) {
      state.isPersonalInfoLoading = true;
    },

    toggleBusinessDocsLoading(state) {
      state.isBusinessDocsLoading = !state.isBusinessDocsLoading;
    },

    togglePersonalDocsLoading(state) {
      state.isPersonalDocsLoading = !state.isPersonalDocsLoading;
    },

    toggleShareholdersListLoading(state) {
      state.isShareholdersListLoading = !state.isShareholdersListLoading;
    },

    toggleBusinessInfoLoading(state) {
      state.isBusinessInfoLoading = !state.isBusinessInfoLoading;
    },

    toggleTimezoneLoading(state) {
      state.isTimezoneLoading = !state.isTimezoneLoading;
    },

    toggleBusCategoryLoading(state) {
      state.isBusCategoryLoading = !state.isBusCategoryLoading;
    },

    toggleBusTypesLoading(state) {
      state.isBusTypesLoading = !state.isBusTypesLoading;
    },
    toggleOccupationTypesLoading(state) {
      state.isOccupationTypesLoading = !state.isOccupationTypesLoading;
    },

    toggleMccCodesLoading(state) {
      state.isMccCodesLoading = !state.isMccCodesLoading;
    },

    toggleBusinessDocTypesLoading(state) {
      state.isBusinessDocTypesLoading = !state.isBusinessDocTypesLoading;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    hasPersonalInfoError(state, action) {
      state.isPersonalInfoLoading = false;
      state.error = action.payload;
    },

    // GET KYCLEVELS
    getKycLevelsSuccess(state, action) {
      state.isLoading = false;
      state.kycLevels = action.payload;
    },

    getPersonalKycDocTypesSuccess(state, action) {
      state.isLoading = false;
      state.personalKycDocTypes = action.payload;
    },

    getTimezonesSuccess(state, action) {
      state.isTimezoneLoading = false;
      state.timezones = action.payload;
    },

    getPersonalInfoSuccess(state, action) {
      state.isPersonalInfoLoading = false;
      state.personalInfo = action.payload;
    },

    clearPersonalInfo(state) {
      state.personalInfo = null;
    },

    getPersonalDocumentsSuccess(state, action) {
      state.isPersonalDocsLoading = false;
      state.personalDocuments = action.payload;
    },

    clearPersonalDocuments(state) {
      state.personalDocuments = null;
    },

    getBusinessInfoSuccess(state, action) {
      state.isBusinessInfoLoading = false;
      state.businessInfo = action.payload;
    },

    clearBusinessInfo(state) {
      state.businessInfo = null;
    },

    getBusinessDocTypesSuccess(state, action) {
      state.isBusinessDocTypesLoading = false;
      state.businessDocTypes = action.payload;
    },

    getMccCodesSuccess(state, action) {
      state.isMccCodesLoading = false;
      state.mccCodes = action.payload;
    },

    getShareholderDocTypesSuccess(state, action) {
      state.isLoading = false;
      state.shareholderDocTypes = action.payload;
    },

    getBusinessCategorySuccess(state, action) {
      state.isBusCategoryLoading = false;
      state.businessCategories = action.payload;
    },

    getBusinessTypeSuccess(state, action) {
      state.isBusTypesLoading = false;
      state.businessTypes = action.payload;
    },

    getShareholdersListSuccess(state, action) {
      state.isShareholdersListLoading = false;
      state.shareholdersList = action.payload;
    },

    clearShareholdersList(state) {
      state.shareholdersList = [];
    },

    getBusinessDocsSuccess(state, action) {
      state.isBusinessDocsLoading = false;
      state.businessDocuments = action.payload;
    },

    getOccupationTypesSuccess(state, action) {
      state.isOccupationTypesLoading = false;
      state.occupationTypes = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {} = slice.actions;

// ----------------------------------------------------------------------

export function getKycLevels() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await stbServer.get(API_ENDPOINTS.kycLevels);
      console.log(response);
      dispatch(slice.actions.getKycLevelsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createUpgradeTicket(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await stbServer.post(API_ENDPOINTS.ticket, data);
      return response;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      console.log(error);
      throw new Error(error?.message ?? "Failed");
    }
  };
}

export function getTimezones(data) {
  return async (dispatch) => {
    dispatch(slice.actions.toggleTimezoneLoading());
    try {
      const response = await stbServer.get(API_ENDPOINTS.timezones);
      dispatch(slice.actions.getTimezonesSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.toggleTimezoneLoading(error));
      console.log(error);
      throw new Error(error?.message ?? "Failed");
    }
  };
}

export function getPersonalInfo(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startPersonalInfoLoading());
    try {
      const response = await stbServer.get(
        `${API_ENDPOINTS.shareholder}${id}/`
      );
      dispatch(slice.actions.getPersonalInfoSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasPersonalInfoError(error));
      console.log(error);
      throw new Error(error?.message ?? "Failed");
    }
  };
}

export function clearPersonalInfo() {
  return async (dispatch) => {
    dispatch(slice.actions.clearPersonalInfo());
  };
}

export function getPersonalDocuments() {
  return async (dispatch) => {
    dispatch(slice.actions.togglePersonalDocsLoading());
    try {
      const response = await stbServer.get(API_ENDPOINTS.shareholderKyc);
      dispatch(slice.actions.getPersonalDocumentsSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.togglePersonalDocsLoading());
      console.log(error);
      throw new Error(error?.message ?? "Failed");
    }
  };
}

export function clearPersonalDocuments() {
  return async (dispatch) => {
    dispatch(slice.actions.clearPersonalDocuments());
  };
}

export function getBusinessInfo() {
  return async (dispatch) => {
    dispatch(slice.actions.toggleBusinessInfoLoading());
    try {
      const response = await stbServer.get(API_ENDPOINTS.senderBusiness);
      dispatch(slice.actions.getBusinessInfoSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.toggleBusinessInfoLoading(error));
      console.log(error);
      throw new Error(error?.message ?? "Failed");
    }
  };
}

export function clearBusinessInfo() {
  return async (dispatch) => {
    dispatch(slice.actions.clearBusinessInfo());
  };
}

export function getBusinessDocTypes() {
  return async (dispatch) => {
    dispatch(slice.actions.toggleBusinessDocTypesLoading());
    try {
      const response = await stbServer.get(API_ENDPOINTS.businessDocTypes);
      dispatch(slice.actions.getBusinessDocTypesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.toggleBusinessDocTypesLoading(error));
      console.log(error);
      throw new Error(error?.message ?? "Failed");
    }
  };
}

export function getMccCodes() {
  return async (dispatch) => {
    dispatch(slice.actions.toggleMccCodesLoading());
    try {
      const response = await stbServer.get(API_ENDPOINTS.MCC_CODES);
      dispatch(slice.actions.getMccCodesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.toggleMccCodesLoading(error));
      console.log(error);
      throw new Error(error?.message ?? "Failed");
    }
  };
}

export function getShareholderDocTypes() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await stbServer.get(API_ENDPOINTS.shareholderDocTypes);
      dispatch(slice.actions.getShareholderDocTypesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      console.log(error);
      throw new Error(error?.message ?? "Failed");
    }
  };
}

export function getBusinessCategory() {
  return async (dispatch) => {
    dispatch(slice.actions.toggleBusCategoryLoading());
    try {
      const response = await stbServer.get(API_ENDPOINTS.businessCategory);
      dispatch(slice.actions.getBusinessCategorySuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.toggleBusCategoryLoading(error));
      console.log(error);
      throw new Error(error?.message ?? "Failed");
    }
  };
}

export function getBusinessType() {
  return async (dispatch) => {
    dispatch(slice.actions.toggleBusTypesLoading());
    try {
      const response = await stbServer.get(API_ENDPOINTS.businessType);
      console.log("ad23d3", response);
      dispatch(slice.actions.getBusinessTypeSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.toggleBusTypesLoading(error));
      console.log(error);
      throw new Error(error?.message ?? "Failed");
    }
  };
}

export function getShareholdersList(id) {
  return async (dispatch) => {
    dispatch(slice.actions.toggleShareholdersListLoading());
    try {
      const response = await stbServer.get(API_ENDPOINTS.shareholder);
      dispatch(slice.actions.getShareholdersListSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.toggleShareholdersListLoading(error));
      console.log(error);
      throw new Error(error?.message ?? "Failed");
    }
  };
}

export function clearShareholdersList() {
  return async (dispatch) => {
    dispatch(slice.actions.clearShareholdersList());
  };
}

export function getBusinessDocs() {
  return async (dispatch) => {
    dispatch(slice.actions.toggleBusinessDocsLoading());
    try {
      const response = await stbServer.get(API_ENDPOINTS.businessKyb);
      dispatch(slice.actions.getBusinessDocsSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.toggleBusinessDocsLoading());
      console.log(error);
      throw new Error(error?.message ?? "Failed");
    }
  };
}

export function getOccupationTypes() {
  return async (dispatch) => {
    dispatch(slice.actions.toggleOccupationTypesLoading());
    try {
      const response = await stbServer.get(API_ENDPOINTS.occupations);
      dispatch(slice.actions.getOccupationTypesSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.toggleOccupationTypesLoading(error));
      console.log(error);
      throw new Error(error?.message ?? "Failed");
    }
  };
}
export function initiateKyc() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await stbServer.post(API_ENDPOINTS.kybSubmit);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      console.log(error);
      throw new Error(error?.message ?? "Failed");
    }
  };
}
