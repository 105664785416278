// ----------------------------------------------------------------------

import { declaration } from "stylis";

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const en = {
  good_morning: `Good Morning`,
  good_afternoon: `Good Afternoon`,
  good_evening: `Good Evening`,
  dashboard: `Dashboard`,
  send_now: `Send Now`,
  total_operation_amount: `Total Operation Amount`,
  operation_pending: `Operation Pending`,
  operation_completed: `Operation Completed`,
  operation_rejected: `Operation Rejected`,
  operation_amount: `Operation Amount`,
  created: `Created`,
  completed: `Completed`,
  pending: `Pending`,
  rejected: `Rejected`,
  number_of_operations: `Number of Operations`,
  country_wise_operations: `Country Wise Operations`,
  beneficiary_wise_operations: `Beneficiary Wise Operations`,
  loading: `Loading`,
  no_data_available: `No Data Available`,
  daily: `Daily`,
  weekly: `Weekly`,
  monthly: `Monthly`,
  yearly: `Yearly`,
  amount: `Amount`,
  count: `Count`,
  delete_operation_title: `Are you sure want to delete the operation with id`,
  delete_operation_warning: `Please ensure that no payments have been made against this operation, as deleting the operation will result in the permanent loss of any associated payments.`,
  operation_list_title: `Operation: List`,
  operation_list: `Operation List`,
  operation: `Operation`,
  new_operation: `New Operation`,
  delete: `Delete`,
  id: `ID`,
  created_date: `Created Date`,
  sender_mto: `Sender MTO`,
  end_beneficiary: `End Beneficiary`,
  sent_value: `Sent Value`,
  recv_value: `Recv Value`,
  status: `Status`,
  payment_qr: `Payment QR`,
  download: `Download`,
  dense: `Dense`,
  operation_deleted_successfully: `Operation Deleted Successfully`,
  operation_delete_failed: `Operation deletion failed, please try again`,
  address_copied_successfully: `Address Copied Successfully`,
  error_fetching_address: `Error Fetching Address`,
  payment_proof_submitted: `Proof of Payment Submitted`,
  payment_info: `Payment Info`,
  example_address: `Enter the address from where you will transfer the funds.`,
  payment_progress: `Payment Progress`,
  payment_in_progress: `We are pleased to inform you that your payment order #{{referenceNumber}} is
            currently being processed. We expect the completion of this
            transaction within the next 3 to 5 business days.`,
  already_completed_click_here: `Payment already completed? Click here to upload proof of payment.`,
  sit_back: `Sit back and relax while we verify your payment!`,
  payment_doc: `Payment Document`,
  currency: `Currency`,
  network: `Network`,
  from_address: `From Address`,
  add_proof_of_payment: `Add Proof of Payment`,
  claim_details_submitted: `Claim Details Submitted`,
  payment_blockchain_hash: `Payment Blockchain Hash`,
  client_info: `Client Info`,
  beneficiary_info: `Beneficiary Info`,
  transfer_details: `Transfer Details`,
  preview_and_submit: `Preview and Submit`,
  funding_source: `Funding Source`,
  funding_source_error: `Error while fetching Funding Source data`,
  operation_created_successfully: `Operation Created Successfully!`,
  operation_creation_failed: `Operation creation failed, please try again`,
  print: `Print`,
  back: `Back`,
  confirm_and_submit: `Confirm and Submit`,
  next: `Next`,
  incomplete_kyb: `Incomplete KYB/KYC`,
  incomplete_kyb_message: `We're unable to process operations until your KYC verification
                is complete. Please finish your KYC to gain access to this
                feature.`,
  go_to_kyc: `Go to KYC`,
  relationship_error: `Error while fetching relationship data`,
  document_downloaded: `Document downloaded successfully!`,
  operation_declaration: `This transaction is being done by the sender himself and not on behalf of someone else.`,
  type: `Type`,
  purpose: `Purpose`,
  documents: `Documents`,
  allowed_formats: `*You can upload image, pdf, document or excel files.`,
  upload: `Upload`,
  upload_document: `Upload Document`,
  drop_files: `Drop or Select file`,
  drop_files_desc: `Drop files here or click`,
  browse: `browse`,
  through_machine: `through your machine`,
  add_new_receiver: `Add New Receiver`,
  transfer_amount: `Transfer Amount`,
  sender_mto_fee: `Sender MTO Fee`,
  beneficiary_mto_fee: `Beneficiary MTO Fee`,
  other_fee: `Other Fee`,
  total_fee: `Total Fee`,
  total: `Total`,
  payment_ref_helper: `Format: Field should start with INV_, POP_, or REF_, contain maximum 2 lines of 35x characters, with only underscore after the first 3 alphabets, and separated by '/', no spaces. Example INV_RFB/BET072.`,
  total_deposit_amount: `Total Deposit Amount`,
  amount_to_payout: `Amount to Payout`,
  sender_country: `Sender Country`,
  payment_reference: `Payment Reference`,
  purpose_of_operation: `Purpose of Operation`,
  purpose_description: `Purpose Description`,
  relationship: `Relationship`,
  conversion_rate: `Conversion Rate`,
  date_of_operation: `Date of Operation`,
  created_by: `Created By`,
  first_name: `First Name`,
  last_name: `Last Name`,
  business_name: `Business Name`,
  primary_contact_name: `Primary Contact Name`,
  account_holder_name: `Account Holder Name`,
  account_type: `Account Type`,
  country: `Country`,
  bank_name: `Bank Name`,
  branch: `Branch`,
  account_number: `Account Number`,
  swift_bic_code: `Swift BIC Code/Fedwire`,
  transit_number: `Transit Number`,
  aba_routing_number: `ABA Routing Number`,
  ifsc_code: `IFSC Code`,
  iban: `IBAN/SEPA`,
  lei_code: `LEI Code`,
  address: `Address`,
  bank: `Bank`,
  operation_details: `Operation Details`,
  operation_details_title: `Operation: Details`,
  sender_details: `Sender Details`,
  phone: `Phone`,
  city: `City`,
  province_state: `Province/State`,
  beneficiary_details: `Beneficiary Details`,
  financial_details: `Financial Details`,
  status_history: `Status History`,
  payments: `Payments`,
  payment_order_number: `Payment Order Number`,
  receiver_mto: `Receiver MTO`,
  date: `Date`,
  operation_status_title: `Operation: Status`,
  operation_status: `Operation Status`,
  operation_initiated: `Operation Initiated`,
  payment_received_from_customer: `Payment Received from Customer`,
  outgoing_operation_order_assigned: `Outgoing Operation Order Assigned`,
  passed_aml_kyc_checks: `Passed AML/KYC Checks`,
  operation_in_progress: `Operation in Progress`,
  transferred_to_end_beneficiary: `Transferred to End Beneficiary`,
  remarks: `Remarks`,
  updated_by: `Updated By`,
  sender_address: `Sender Address`,
  action: `Action`,
  no_data: `No Data`,
  business_receivers_list_title: `Business Receivers: List`,
  business_receivers_list: `Business Receivers List`,
  email: `Email`,
  xrpl_tag: `Xrpl Tag`,
  business_receivers: `Business Receivers`,
  new_receiver: `New Receiver`,
  business_receiver_create_title: `Business Receiver: Create`,
  business_receiver: `Business Receiver`,
  create: `Create`,
  update: `Update`,
  business_info: `Business Information`,
  financial_info: `Financial Info`,
  reciever: `Reciever`,
  company_information: `Company Information`,
  contact_details: `Contact Details`,
  business_operating_address: `Business Operating Address`,
  user_account_settings_title: `User: Account Settings`,
  account: `Account`,
  account_settings: `Account Settings`,
  user_info: `User Info`,
  change_password: `Change Password`,
  key_controller: "Are you a Key Controller of the Business?",
  dob: `Date of Birth`,
  primary_contact_no: `Primary Contact Number`,
  secondary_contact_no: `Secondary Contact Number`,
  postal_code: `Zip/Postal Code`,
  source_of_wealth: `Source of Wealth`,
  tax_id: `Tax ID`,
  doing_business_as: `Doing Business As`,
  legal_entity: `Legal Entity`,
  business_category: `Business Category`,
  business_sub_category: `Business Sub Category`,
  registered_business_address: `Registered Business Address`,
  business_communication_address: `Business Communication Address`,
  anticipated_business_activity: `Anticipated Business Activity`,
  average_transaction_amount: `Average Transaction Amount`,
  frequency: `Frequency`,
  volume: `Volume`,
  jurisdiction: `Jurisdiction`,
  other_information: `Other Information`,
  timezone: `Timezone`,
  company_type: `Company Type`,
  incorporation_date: `Incorporation Date`,
  registration_umber: `Registration Number`,
  website: `Website`,
  mcc_code: `MCC Code`,
  save_changes: `Save Changes`,
  kyc_not_done_title: "Please verify your merchant account to get started",
  kyc_not_done_desc1: ` To aid the government in combating the financing of terrorism and
              money laundering activities, federal law mandates that all
              financial institutions acquire, verify, and document information
              identifying each legal entity and individual opening an account.`,
  kyc_not_done_desc2: ` For your understanding: Upon opening an account, we will request
              details such as your name, address, date of birth, and relevant
              documentation pertaining to the legal entity for which the account
              is being opened.`,
  kyc_not_done_desc3: `This includes information about its control persons and beneficial
              owners, facilitating our ability to identify both you and the
              legal entity you represent.`,
  kyc_not_done_desc4: ` Additionally, we may require identification documentation for you,
              the legal entity, and any affiliated entities or individuals
              associated with the legal entity for which the account is being
              established.`,
  kyc_not_done_desc5: `  The account verification process typically requires approximately
              1-3 working days.`,
  start_kyb: `Start KYB`,
  kyc_inprocess_title: `Account Verification in Process`,
  kyc_inprocess_desc: `The account verification process typically requires approximately 1-3
          working days.`,
  kyc_rejected_title: `Account Verification Failed`,
  kyc_rejected_desc: ` Your KYB is failed, please click the button below to review and
            update the necessary information to get your business started.`,
  review_and_update: `Review and Update`,
  personal_info: `Personal Information`,
  personal_documents: `Personal Documents`,
  business_documents: `Business Documents`,
  business_ownership: `Business Ownership`,
  preview: `Preview`,
  kyc_form: `KYC Form`,
  kyb_kyc_documents: `KYB/KYC Documents`,
  close: "Close",
  note: `NOTE:`,
  note_desc: `Please upload your Passport and Proof of Address (e.g., utility bill
            or bank statement); Venezuelan citizens must also upload their
            National ID.`,
  shareholder_note_desc: ` We require details of all Ultimate Beneficial Owners (UBOs) who own
            10% or more of the company.`,
  id_proof: `ID Proof`,
  front_part_doc: `Front part of the document`,
  back_part_doc: `Back part of the document`,
  address_proof: `Address Proof`,
  remove: `Remove`,
  other_documents: `Other Documents`,
  add_document: `Add Document`,
  upload_and_continue: `Upload and Continue`,
  are_you_shareholder: `Are you a shareholder/UBO of the company?`,
  are_you_only_shareholder: `Are you the only shareholder/UBO of the company?`,
  shareholder_ubo: `Shareholder/UBO`,
  entity_type: `Entity Type`,
  add: `Add`,
  have_subsidiary: `Does this company have any parents or subsidiaries?`,
  update_and_continue: `Update and Continue`,
  business_reg_address: `Business Registration Address`,
  business_comm_mailing_address: `Business Communication/Mailing Address`,
  jurisdication: `Jurisdication`,
  company_reg_date: `Company Registration Date`,
  phone_number: `Phone Number`,
  ownership: `Ownership`,
  country_of_reg: `Country of Registration`,
  citizenship: `Citizenship`,
  shareholders: `Shareholders`,
  declaration_title: `Declaration of Accuracy and Authenticity of Information`,
  declaration_desc: `I hereby confirm that all the information and documents
                  provided are true, accurate, and legally valid to the best of
                  my knowledge. I acknowledge that any false or misleading
                  information provided in the documentation may result in legal
                  consequences, including, but not limited to, the rejection of
                  the registration process or legal actions.`,
  name: `Name`,
  id_doc_no: `Identification Document Number`,
  position_role: `Position-Role`,
  reviewed_and_accepted: `I have reviewed and accept the`,
  t_c_and_contract: `terms, conditions, and contract.`,
  chat: `Chat`,
  new_ticket: `New Ticket`,
  select_connvo_to_start: `Select a conversation to start chatting.`,
  type_a_message: `Type a message...`,
  close_ticket: `Close Ticket`,
  reopen_ticket: `Reopen Ticket`,
  are_you_sure: `Are you sure you want to`,
  ticket: `ticket`,
  reopen: `Reopen`,
  all: `All`,
  support: `Support`,
  kyc: `KYC`,
  open_tickets: `Open Tickets`,
  new: `New`,
  open: `Open`,
  closed: `Closed`,
  no_tickets_avail: `No tickets available!`,
  receivers: `Receivers`,
  profile: `Profile`,
  user_account: `User Account`,
  user_profile: `User Profile`,
  user_kyb_kyc: `User KYB/KYC`,
  claim_payment: `Claim Payment`,
  submit_proof_of_payment: `Submit Proof of Payment`,
  proof_of_payment: `Proof of Payment`,
  verification_completed: `Verification Completed`,
  doc_type: `Document Type`,
  updated_date: `Last Updated On`,
  operation_create: `Operation: Create`,
  target_country: `Target Country`,
  doc_name: `Document Name`,
  cancel: `Cancel`,
  receiver: `Receiver`,
  receiver_account: `Receiver Account`,
  receiver_type: `Receiver Type`,
  line_of_business: `Line of Business`,
  corporation_type: `Corporation Type`,
  primary_contact_designation: `Primary Contact Designation`,
  benef_email_tooltip: `Beneficiary will get updates regarding remittances on this email address.`,
  country_of_operation: `Country of Operation`,
  address_line_one: `Address Line One`,
  address_line_two: `Address Line Two`,
  address_line_three: `Address Line Three`,
  branch_no: `Branch Number`,
  bank_address: `Bank Address`,
  create_user: `Create User`,
  amt_to_remit: `Amount to Remit`,
  old_password: `Old Password`,
  new_password: `New Password`,
  confirm_new_password: `Confirm New Password`,
  yes: `Yes`,
  no: `No`,
  auth_rep_dob: `Authorized representative's date of birth`,
  key_controller_authority: `Key Controller's Designated Authority`,
  passport_number: `Passport Number`,
  res_address: `Residential Address`,
  individual: `Individual`,
  company: `Company`,
  contact_no: `Contact Number`,
  shareholding_percentage: `Shareholding Percentage`,
  docs_req: `The following documents are required`,
  tax_id_doc: `Tax ID Document`,
  proof_of_address: `Proof of Address`,
  articles_of_incorporation: `Articles of Incorporation`,
  proof_of_owners: `Proof of Owners of 10% or more`,
  prood_of_key_controller: `Proof of Key Controllers (Organizational Structure with Director level and higher)`,
  photo_id: `Photo ID`,
  legal_company_name: `Legal Company Name`,
  dba_optional: `Doing Business As (Optional)`,
  reg_legal_entity: `Registered legal entity of your business`,
  occupation: `Occupation`,
  enter_occupation: `Enter your occupation`,
  reg_comm_add_same: `Business Communication address same as Registered Address`,
  select_mul_countries: `You can select multiple countries`,
  juris_countries: `Jurisdiction Countries`,
  company_url: `Company Website URL (optional)`,
  add_ticket: `Add Ticket`,
  category: `Category`,
  subject: `Subject`,
  message: `Message`,
  logout: `Logout`,
  home: `Home`,
  log_in_to: `Log in to`,
  new_user: `New User?`,
  create_account: `Create an account`,
  login_greeting: `Hi, Welcome back!`,
  password: `Password`,
  forgot_password: `Forgot Password?`,
  login: `Login`,
  sign_up_to: `Sign up to`,
  already_user: `Already user?`,
  auth_rep_details: `Authorized representative's details:`,
  re_email: `Re-enter Email`,
  auth_rep_des: `Designation of authorized representative"`,
  dob_as_id: `Date of birth as per identity card`,
  by_clicking_next: `By clicking "NEXT", you confirm that you have read and understood the`,
  privacy_policy: `Privacy Policy`,
  agree_to_terms: `, and agree to its terms.`,
  company_details: `Company details:`,
  company_details_tooltip: `Details as registered with the government of your country`,
  congratulations: `Congratulations!`,
  signup_success: `You have been successfully signed up.`,
  recv_mail: `You will receive a confirmation email containing a link, you can use that link to reset your password.`,
  go_to_login: `Go to login`,
  operating_province_state: `Operating Province/State`,
  operating_city: `Operating City`,
  operating_postal_code: `Operating ZIP/Postal Code`,
  open_and_review: `Please open and review the Privacy Policy document before checking this box`,
};

export default en;
