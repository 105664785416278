// ----------------------------------------------------------------------

export default function Link(theme) {
  return {
    MuiLink: {
      defaultProps: {
        underline: "hover",
        // color:
        //   theme.palette.mode === "light"
        //     ? theme.palette.vertex.blueMain
        //     : theme.palette.vertex.yellowMain,
      },
    },
  };
}
