import { Link as RouterLink } from "react-router-dom";
// @mui
import { Alert, Tooltip, Stack, Typography, Link, Box } from "@mui/material";
// auth
import { useAuthContext } from "../../auth/useAuthContext";
// routes
import { PATH_AUTH } from "../../routes/paths";
// layouts
import LoginLayout from "../../layouts/login";
//
import AuthLoginForm from "./AuthLoginForm";
import AuthWithSocial from "./AuthWithSocial";
import { useSelector } from "react-redux";
import { useLocales } from "../../locales";

// ----------------------------------------------------------------------

export default function Login() {
  const { method } = useAuthContext();
  const { mtoConfig } = useSelector((state) => state.config);
  const { t } = useLocales();

  return (
    <LoginLayout>
      <Stack spacing={2} sx={{ mb: 5, position: "relative" }}>
        <Typography variant="h4">
          {t("log_in_to")} {mtoConfig?.mto?.name}
        </Typography>

        <Stack direction="row" spacing={0.5}>
          <Typography variant="body2">{t("new_user")}</Typography>

          <Link
            component={RouterLink}
            to={PATH_AUTH.singup}
            variant="subtitle2"
          >
            {t("create_account")}
          </Link>
        </Stack>
      </Stack>

      <AuthLoginForm />
    </LoginLayout>
  );
}
