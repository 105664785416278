import { Navigate, useRoutes } from "react-router-dom";
// auth
import AuthGuard from "../auth/AuthGuard";
import GuestGuard from "../auth/GuestGuard";
// layouts
import MainLayout from "../layouts/main";
import CompactLayout from "../layouts/compact";
import DashboardLayout from "../layouts/dashboard";
// config
import { PATH_AFTER_LOGIN } from "../config-global";
//
import {
  // Auth
  IndividualReceiverListPage,
  BusinessReceiverListPage,
  LoginPage,
  RegisterPage,
  VerifyCodePage,
  NewPasswordPage,
  UpdatePasswordPage,
  ResetPasswordPage,
  DashboardPage,
  KycLevelsPage,
  RemittancePage,
  RemittanceListPage,
  ChatPage,
  Page500,
  Page403,
  Page404,
  HomePage,
  MaintenancePage,
  SignupPage,
  IndividualReceiverCreatePage,
  IndividualReceiverEditPage,
  BusinessReceiverCreatePage,
  BusinessReceiverEditPage,
  RemittanceDetailsPage,
  UserAccountPage,
  UserKyc,
} from "./elements";
import { PATH_DASHBOARD } from "./paths";

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    // Auth
    {
      path: "auth",
      children: [
        {
          path: "signup",
          element: (
            <GuestGuard>
              <SignupPage />
            </GuestGuard>
          ),
        },
        {
          path: "login",
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
        {
          path: "register",
          element: (
            <GuestGuard>
              <RegisterPage />
            </GuestGuard>
          ),
        },
        { path: "login-unprotected", element: <LoginPage /> },
        { path: "register-unprotected", element: <RegisterPage /> },
        {
          element: <CompactLayout />,
          children: [
            { path: "reset-password", element: <ResetPasswordPage /> },
            { path: "new-password", element: <NewPasswordPage /> },

            { path: "update-password", element: <UpdatePasswordPage /> },

            { path: "verify", element: <VerifyCodePage /> },
          ],
        },
      ],
    },

    // Dashboard
    {
      path: "dashboard",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: "app", element: <DashboardPage /> },
        {
          path: PATH_DASHBOARD.user.root,
          children: [
            {
              element: <Navigate to="/dashboard/user/profile" replace />,
              index: true,
            },
            { path: "profile", element: <UserAccountPage /> },
            { path: "kyc", element: <UserKyc /> },
          ],
        },
        {
          path: "receivers",
          children: [
            {
              element: <Navigate to="/dashboard/receivers/list" replace />,
              index: true,
            },
            {
              path: "edit/:userId",
              element: <BusinessReceiverEditPage />,
            },

            { path: "list", element: <BusinessReceiverListPage /> },
            {
              path: "create",
              element: <BusinessReceiverCreatePage />,
            },
          ],
        },
        {
          path: "operation",
          children: [
            {
              element: <Navigate to="/dashboard/operation/list" replace />,
              index: true,
            },
            { path: "list", element: <RemittanceListPage /> },
            { path: "new", element: <RemittancePage /> },
            { path: ":id", element: <RemittanceDetailsPage /> },
          ],
        },
        {
          path: "chat",
          children: [
            { element: <ChatPage />, index: true },
            { path: "new", element: <ChatPage /> },
            { path: ":conversationKey", element: <ChatPage /> },
          ],
        },
      ],
    },

    // Main Routes
    {
      element: <MainLayout />,
      children: [
        {
          element: (
            <GuestGuard>
              <HomePage />
            </GuestGuard>
          ),
          index: true,
        },
      ],
    },
    {
      element: <CompactLayout />,
      children: [
        { path: "maintenance", element: <MaintenancePage /> },
        { path: "500", element: <Page500 /> },
        { path: "404", element: <Page404 /> },
        { path: "403", element: <Page403 /> },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}
