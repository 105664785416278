export const API_ENDPOINTS = {
  countries: "/country/",
  city: "/city/",
  province: "/province/",
  changePassword: "/mto/user/change_password/",
  login: "/customer/login/",
  senderRegister: "/customer/register/",
  profile: "/customer/profile/",
  deposits: "/sender/fund/deposit/",
  depositStatusOptions: "/mto/deposits/depositstatusoption/",
  timezones: "/timezones/",
  updateUser: "/customer/update_user/",

  // Statement
  statement: "/customer/wallet/transactions/",

  // Dashboard
  depositDetails: "/customer/dashboard/deposit_details/",
  kycLimitDetails: "/customer/dashboard/kyc_details/",

  // Support
  categories: "/usersupport/category/",
  ticket: "/usersupport/user/ticket/",
  ticketReply: "/usersupport/user/reply/",

  // kyc
  kycLevels: "/kyc/kyc_level/",
  userKyc: "/kyc/user_kyc/",
  senderDocument: "/sender/document/",
  documentType: "/kyc/document_type/",
  remittanceDocumentType: "/sender/document_types/",

  //Config
  purpose: "/remittance/puposes/",
  coin: "/coin/",
  supportedCoins: "/supported_coin/",
  currency: "/currency/",
  balance: "/customer/wallet/balance/",
  financialInstruments: "/financial/instruments/",

  // remittance
  receiversIndividual: "/customer/receiver/individual/",
  receiversBusiness: "/customer/receiver/business/",
  remittanceReceiver: "/customer/receiver/",
  selectMto: "/remittance/auto_select_mto/",
  calculateFee: "/remittance/calculate_fee/",
  remittance: "/customer/remittance/",
  financial: "/financial/",

  forgotPassword: "/mto/user/reset_password/",
  updatePassword: "/mto/user/update_password/",
  newPassword: "/mto/user/new_password/",

  remittanceOverallAmounts: "/customer/dashboard/remittance_overall_amount/",
  remittanceAmountGraph: "/customer/dashboard/remittance_amount_graph/",
  remittanceNumberGraph: "/customer/dashboard/remittance_no_graph/",
  remittanceReceiverGraph: "/customer/dashboard/remittance_receiver_graph/",
  countrywiseRemittanceGraph:
    "/customer/dashboard/country_based_remittance_graph/",

  senderBusiness: "/sender/business/",
  businessDocTypes: "/sender/business/kyb/doc_types/",
  shareholderDocTypes: "/sender/shareholder/doc_type_list/",

  shareholder: "/sender/shareholder/",
  shareholderKyc: "/sender/shareholder/kyc/",
  businessCategory: "/sender/business/category/",
  businessType: "/sender/business/type/",
  businessKyb: "/sender/business/kyb/",
  fileUpload: "/file/",
  occupations: "/occupations/",
  kybSubmit: "/sender/business/kyb/submit/",
  file: "/file/",
  networks: "/networks/",
  paymentAddress: "/remittance/payment_address/",
  statuses: "/remittance/statuses/",
  REFRESH_TOKEN_API: "/refresh/",
  MCC_CODES: "sender/business/mcc_code_list",

  receiversType: "/receiver/receiver_types/",
  receiverLineOfBusiness: "/receiver/line_of_business_list/",
  financialAccountType: "/financial/account_types/",
  remittanceRelationship: "/remittance/relationship_list/",
  remittanceFunding: "/remittance/funding_source_list/",
};
