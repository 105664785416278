import { createSlice } from "@reduxjs/toolkit";
// utils
import axios, { stbWithoutAuth } from "../../utils/axios";
import { API_ENDPOINTS } from "src/api";
import stbServer from "../../utils/axios";

// ----------------------------------------------------------------------

const initialState = {
  isCountriesLoading: false,
  isProvincesLoading: false,
  isCitiesLoading: false,
  isLoading: false,
  error: null,
  countries: [],
  provinces: [],
  cities: [],
  purpose: [],
  senderDocuments: [],
  documentTypes: [],
  remittanceDocumentTypes: [],
  coins: [],
  supportedCoins: [],
  balance: {},
  currencies: [],
  financialInstruments: [],
  mtoConfig: {
    colors: {
      name: "blue",
      lighter: "#4b7ace",
      light: "#5a9ed1",
      main: "#284f93",
      dark: "#1f3d71",
      darker: "#182f58",
      contrastText: "#fff",
    },
    // favicon: 'https://media.dev.eduley.com/media/letter-d_Ibq4dLP.png',
    // favicon: null,
    logo: "",
    terms_and_condition_link: "https://twitter.com",
    privacy_policy: "https://sdfsdfs",
    mto: {
      name: "mto name",
      country: { id: 13, name: "Australia", code2: "AU", phone: 61 },
    },
    tagline: "Mto Tagline",
  },
  networks: [],
};

const slice = createSlice({
  name: "config",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    startCountriesLoading(state) {
      state.isCountriesLoading = true;
    },

    startProvincesLoading(state) {
      state.isProvincesLoading = true;
    },

    startCitiesLoading(state) {
      state.isCitiesLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    hasCountriesError(state, action) {
      state.isCountriesLoading = false;
      state.error = action.payload;
    },

    hasProvincesError(state, action) {
      state.isProvincesLoading = false;
      state.error = action.payload;
    },

    hasCitiesError(state, action) {
      state.isCitiesLoading = false;
      state.error = action.payload;
    },

    // GET KYCLEVELS
    getCountriesSuccess(state, action) {
      state.isCountriesLoading = false;
      state.countries = action.payload;
    },

    getProvinceSuccess(state, action) {
      state.isProvincesLoading = false;
      state.provinces = action.payload;
    },

    getCitySuccess(state, action) {
      state.isCitiesLoading = false;
      state.cities = action.payload;
    },

    getPurposeSuccess(state, action) {
      state.isLoading = false;
      state.purpose = action.payload;
    },
    getSenderDocumentSuccess(state, action) {
      state.isLoading = false;
      state.senderDocuments = action.payload;
    },
    getDocumentTypeSuccess(state, action) {
      state.isLoading = false;
      state.documentTypes = action.payload;
    },
    getRemittanceDocumentTypeSuccess(state, action) {
      state.isLoading = false;
      state.remittanceDocumentTypes = action.payload;
    },

    getCoinsSuccess(state, action) {
      state.isLoading = false;
      state.coins = action.payload;
    },

    getSupportedCoinsSuccess(state, action) {
      state.isLoading = false;
      state.supportedCoins = action.payload;
    },
    getBalanceSuccess(state, action) {
      state.isLoading = false;
      state.balance = action.payload;
    },

    getAllCurrenciesSuccess(state, action) {
      state.isLoading = false;
      state.currencies = action.payload;
    },

    getFinancialInstrumentsSuccess(state, action) {
      state.isLoading = false;
      state.financialInstruments = action.payload;
    },
    getMtoConfigSuccess(state, action) {
      state.isLoading = false;
      state.mtoConfig = action.payload;
    },

    getNetworksSuccess(state, action) {
      state.isLoading = false;
      state.networks = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {} = slice.actions;

// ----------------------------------------------------------------------

export function getMtoConfigData() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const domainName = window.location.hostname;
      // if
      const response = await stbWithoutAuth.get(
        //  'https://db5g670oracyr.cloudfront.net/media/mto_theme/ven-customer-test-spendthebit.netlify.app2.json'
        `${process.env.REACT_APP_CLOUDFRONT_URL}media/mto_theme/${process.env.REACT_APP_FRONTEND_DOMAIN}.json`
      );
      // console.log(response);

      dispatch(slice.actions.getMtoConfigSuccess(response.data));
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCountries() {
  return async (dispatch) => {
    dispatch(slice.actions.startCountriesLoading());
    try {
      const response = await stbServer.get(API_ENDPOINTS.countries, {
        params: { limit: 1000 },
      });
      dispatch(slice.actions.getCountriesSuccess(response.data.results));
    } catch (error) {
      dispatch(slice.actions.hasCountriesError(error));
    }
  };
}

export function getProvince(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startProvincesLoading());
    try {
      const response = await stbServer.get(API_ENDPOINTS.province, {
        params: { country: id, limit: 1000 },
      });
      dispatch(slice.actions.getProvinceSuccess(response.data.results));
    } catch (error) {
      dispatch(slice.actions.hasProvincesError(error));
    }
  };
}

export function getCity(countryId, provinceId) {
  return async (dispatch) => {
    dispatch(slice.actions.startCitiesLoading());
    try {
      const response = await stbServer.get(API_ENDPOINTS.city, {
        params: { country: countryId, region: provinceId, limit: 1000 },
      });
      dispatch(slice.actions.getCitySuccess(response.data.results));
    } catch (error) {
      dispatch(slice.actions.hasCitiesError(error));
    }
  };
}

export function getPurpose() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await stbServer.get(API_ENDPOINTS.purpose);
      dispatch(slice.actions.getPurposeSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getDocumentTypes() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await stbServer.get(API_ENDPOINTS.documentType);
      dispatch(slice.actions.getDocumentTypeSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getRemittanceDocumentTypes() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await stbServer.get(
        API_ENDPOINTS.remittanceDocumentType
      );
      dispatch(slice.actions.getRemittanceDocumentTypeSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getSenderDocument() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await stbServer.get(API_ENDPOINTS.senderDocument);
      dispatch(slice.actions.getSenderDocumentSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCoins() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await stbServer.get(API_ENDPOINTS.coin);
      dispatch(slice.actions.getCoinsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getSupportedCoins() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await stbServer.get(API_ENDPOINTS.supportedCoins);
      dispatch(slice.actions.getSupportedCoinsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getNetworks() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await stbServer.get(API_ENDPOINTS.networks);
      dispatch(slice.actions.getNetworksSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function uploadFile(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    const additionalHeader = {
      "Content-Type":
        "multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW",
    };
    try {
      const response = await stbServer.post(API_ENDPOINTS.file, data, {
        headers: {
          ...stbServer.defaults.headers,
          ...additionalHeader,
        },
      });
      return response?.data?.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function uploadSenderDocument(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await stbServer.post(API_ENDPOINTS.senderDocument, data);
      console.log(response, "adedwqd");
      return response?.data?.data;
      // dispatch(getSenderDocument());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteSenderDocument(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await stbServer.delete(
        `${API_ENDPOINTS.senderDocument}${id}/`
      );
      dispatch(getSenderDocument());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCountryCurrency(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    const params = {
      country: id,
    };
    try {
      const response = await stbServer.get(API_ENDPOINTS.currency, {
        params,
      });
      return response;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAllCurrencies() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await stbServer.get(API_ENDPOINTS.currency);
      dispatch(slice.actions.getAllCurrenciesSuccess(response.data));
      // return response;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getBalance() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await stbServer.get(API_ENDPOINTS.balance);
      dispatch(
        slice.actions.getBalanceSuccess(Number(response.data.data.balance))
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getFinancialInstruments() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await stbServer.get(API_ENDPOINTS.financialInstruments);
      dispatch(
        slice.actions.getFinancialInstrumentsSuccess(response.data.data)
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getDocumentUrlFromId(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await stbServer.get(`${API_ENDPOINTS.file}${id}/`);
      return response;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAddress(coin, blockchain, remittance, from_address) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await stbServer.post(API_ENDPOINTS.paymentAddress, {
        coin,
        blockchain,
        remittance,
        from_address,
      });
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

export function getAllStatuses() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await stbServer.get(API_ENDPOINTS.statuses);
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
