import PropTypes from "prop-types";
// @mui
import { Typography, Stack, Box } from "@mui/material";
// components
import Logo from "../../components/logo";
import Image from "../../components/image";
//
import {
  StyledRoot,
  StyledSectionBg,
  StyledSection,
  StyledContent,
} from "./styles";
import { useSelector } from "src/redux/store";
import { useSettingsContext } from "src/components/settings";
import LanguagePopover from "../dashboard/header/LanguagePopover";
import { useLocales } from "../../locales";

// ----------------------------------------------------------------------

LoginLayout.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  illustration: PropTypes.string,
};

export default function LoginLayout({ children, illustration, title }) {
  const { mtoConfig } = useSelector((state) => state.config);
  const { themeMode } = useSettingsContext();
  const { t } = useLocales();

  return (
    <>
      <Stack direction="row" justifyContent="flex-end" padding={"20px"}>
        <LanguagePopover />
      </Stack>

      <StyledRoot>
        {/* <Logo
        sx={{
          zIndex: 9,
          position: 'absolute',
          mt: { xs: 1.5, md: 5 },
          ml: { xs: 2, md: 5 },
        }}
      /> */}

        <StyledSection>
          <Typography
            variant="h3"
            sx={{ mb: 10, maxWidth: 480, textAlign: "center" }}
          >
            {title || t("login_greeting")}
          </Typography>

          <Image
            disabledEffect
            visibleByDefault
            alt="auth"
            src={mtoConfig.logo}
            sx={{ maxWidth: { xs: 300, md: 360 } }}
          />
        </StyledSection>

        <StyledContent>
          <Stack sx={{ width: 1 }}> {children} </Stack>
        </StyledContent>
      </StyledRoot>
    </>
  );
}
